/* body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

:root {
  --light-blue: #f0fbff;
  --med-pink: #ffe5eb;
  --baby-blue: #96B8F1;
  --trim: #666ABD;
  --blue-hover: #85A8E1;
  --grey: #D9D9D9;
  --title-font-size: 64px;
  --body-font-size: 32px;
  --border-radius: 15px;
}

@font-face {
  font-family: Baloo;
  src: url(Baloo_Paaji/BalooPaaji2-Regular.ttf);
}

@font-face {
  font-family: Baloo-Bold;
  src: url(Baloo_Paaji/BalooPaaji2-ExtraBold.ttf);
}

body {
  background-color: var(--light-blue);
  text-align: center;
  background-image:
    url("data:image/svg+xml,%3Csvg width='347' height='327' viewBox='0 0 347 327' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M112.633 0.482822C79.3604 7.46265 80.3061 81.5586 80.3061 81.5586L-49 117.804L17.0792 403L347 335.755L327.984 250.864C327.984 250.864 272 252.953 256.676 227.972C233.857 190.772 289.478 150.711 289.478 150.711L256.676 62.005L166.352 81.5586C166.352 81.5586 149.727 -7.29886 112.633 0.482822Z' fill='url(%23paint0_linear_23_525)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_23_525' x1='149' y1='0' x2='149' y2='403' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23E4EEFF'/%3E%3Cstop offset='1' stop-color='%23F1F6FF'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E"),
    url("data:image/svg+xml,%3Csvg width='372' height='282' viewBox='0 0 372 282' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M396.954 252.654C422.126 227.547 379.033 163.222 379.033 163.222L471.656 57.6681L251.079 -154.274L0.357319 92.9521L65.4417 156.412C65.4417 156.412 113.304 122.62 140.981 135.74C182.193 155.278 156.309 222.104 156.309 222.104L235.65 281.032L303.639 212.347C303.639 212.347 368.89 280.644 396.954 252.654Z' fill='url(%23paint0_linear_23_529)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_23_529' x1='365.364' y1='273.839' x2='135.489' y2='-78.9581' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23E4EEFF'/%3E%3Cstop offset='1' stop-color='%23FFE6EB'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: bottom left, top right;
  height: 100vh;
  overflow-y: hidden;
}

.logo {
  width: 300px;
  margin: 100px 10px -50px 10px;
}

h1 {
  font-family: Baloo-Bold, sans-serif;
  font-size: var(--title-font-size);
  color: var(--baby-blue);
  -webkit-text-stroke: 3px var(--trim);
}

h2 {
  font-family: Baloo-Bold, sans-serif;
  color: var(--baby-blue);
  font-size: var(--title-font-size);
}

p {
  font-family: Baloo, sans-serif;
  color: var(--trim);
  font-size: var(--body-font-size);
}

.link {
  font-family: Baloo, sans-serif;
}

.input-box {
  width: 800px;
  height: 66px;
  font-family: Baloo, sans-serif;
  color: var(--trim);
  font-size: var(--body-font-size);
  border-radius: var(--border-radius);
  border-color: var(--baby-blue);
  border-width: 3px;
  border-style: solid;
  margin-right: 10px;
  text-align: center;
}

.short-button {
  width: 203px;
  height: 66px;
  font-family: Baloo-Bold, sans-serif;
  font-size: var(--body-font-size);
  background-color: var(--baby-blue);
  border-radius: var(--border-radius);
  border: none;
  color: white;
}

.long-button {
  width: 414px;
  height: 58px;
  margin-top: 30px;
  font-family: Baloo-Bold, sans-serif;
  font-size: var(--body-font-size);
  background-color: var(--baby-blue);
  border-radius: var(--border-radius);
  border: none;
  color: white;
}

button:hover {
  background-color: var(--blue-hover);
  cursor: pointer;
}

/* lobby */

.lobby {
  margin: auto;
  width: 50%;
  padding: 10px;
  height: 440px;
}

.lobby h2 {
  margin-bottom: -5%;
}

.player-list {
  list-style: none;
  background-color: white;
  width: 760px;
  height: 450px;
  font-family: Baloo, sans-serif;
  font-size: var(--body-font-size);
  color: var(--trim);
  border-color: var(--baby-blue);
  border-width: 3px;
  border-style: solid;
  border-radius: var(--border-radius);
  text-align: left;
  overflow-y: auto;
  margin-bottom: 5px;
}

/* question entry */

.question-entry h2 {
  color: var(--trim);
  padding: 10px;
}

.loading {
  margin: auto;
  padding: 10px;
}